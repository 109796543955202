<template>
  <b-row class="match-height">
    <b-col lg="12" md="12">
      <template>
        <b-form @submit.prevent>
          <b-row>
            <b-col lg="4" sm="12">
              <b-card>
                <b-row>
                  <b-col lg="12" sm="12" class="mb-2">
                    <b-alert
                      v-height-fade
                      show
                      dismissible
                      fade
                      class="mb-0"
                      variant="success"
                    >
                      <div class="alert-body">
                        <span>{{
                          $t(
                            "Pages.Review.Title.select_ch_save_req"
                          )
                        }}</span>
                      </div>
                    </b-alert>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Pages.Review.Label.select_ch')"
                      label-for="h-title"
                    >
                      <v-select
                        id="h-channel"
                        v-model="selectedChannel"
                        :dir="
                          () =>
                            localStorage.getItem('language') == 'fa'
                              ? 'rtl'
                              : 'ltr'
                        "
                        label="nickname"
                        :options="channels"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      :label="$t('Pages.Review.Label.insta_id')"
                      label-for="instagram"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="instagram"
                        rules="required"
                      >
                        <b-form-input
                          id="instagram"
                          v-model="instagram"
                          :state="errors.length > 0 ? false : null"
                          name="instagram"
                          placeholder="@"
                        />
                        <p style="padding-top: 3px">
                          <span class="text-sm">{{
                            $t(
                              "Pages.Review.Title.username_without_@"
                            )
                          }}</span>
                        </p>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- submit and reset -->
                  <b-col>
                    <b-button
                      block
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1 mt-2"
                      @click="submit"
                    >
                      {{ $t("Pages.Review.Button.submit_req") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              lg="8"
              sm="12"
              :class="$store.state.appConfig.isRTL ? 'text-right' : 'text-left'"
            >
              <b-card>
                <b-card-text class="pre-formatted">
                  {{ $t("Pages.Review.Title.request_ch_review_txt") }}
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,
  BAlert,
  BCardText,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
    BAlert,
    vSelect,
    BCardText,
    BImg,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      channels: [],
      selectedChannel: { nickname: "", id: 0 },
      instagram: "",
    };
  },
  created() {
    this.getChannelList();
  },
  methods: {
    getChannelList() {
      this.$http.get("/api/channel/list/mini").then((response) => {
        this.channels = response.data;
      });
    },
    submit() {
      if (
        this.instagram.includes("@") ||
        this.instagram.includes("https:://")
      ) {
        this.makeToast(
          "Alert.Error",
          this.$t("Base.Alert.username_without_@"),
          "danger"
        );
      }
      if (this.selectedChannel.id == 0) {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Base.Alert.select_ch"),
          "danger"
        );
      } else {
        const payload = {
          channel_id: this.selectedChannel.id,
          instagram: this.instagram,
        };
        this.$http
          .post(`/api/review/check`, payload)
          .then((response) => {
            if (response.status == 201) {
              this.makeToast(
                this.$t("Base.Alert.Successful"),
                this.$t("Base.Alert.success_review_ch"),
                "success"
              );
              this.selectedChannel.id = 0;
              this.instagram = "";
            } else if (response.status == 206) {
              this.makeToast(
                this.$t("Base.Alert.Error"),
                this.$t("Base.Alert.error_has_review_ch"),
                "danger"
              );
            }
          })
          .catch((e) => {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.error_review_ch"),
              "danger"
            );
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
